/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { sanityClient, urlForSanityImage } from "./services/sanity";
import {
  isFetchingInitialDataAtom,
  heroImagesAtom,
  artistsAtom,
  dropsAtom,
  howItWorksAtom,
  questionsAtom,
} from "./state/atoms";

// import Category from "./sections/Category";
// import Collections from "./sections/Collections";
import Hero from "./sections/Hero";
import HowItWorks from "./sections/HowItWorks";
import Drops from "./sections/Drops";
// import Popular from "./sections/Popular";
import Questions from "./sections/Questions";

import FullScreenLoader from "./components/FullScreenLoader";
import BackToTop from "./components/BackToTop";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

const App: FunctionComponent<{}> = () => {
  const [isFetchingInitialData, setIsFetchingInitialData] = useRecoilState(
    isFetchingInitialDataAtom
  );

  const setHeroImages = useSetRecoilState(heroImagesAtom);
  const setDrops = useSetRecoilState(dropsAtom);
  const setArtists = useSetRecoilState(artistsAtom);
  const setHowItWorks = useSetRecoilState(howItWorksAtom);
  const setQuestions = useSetRecoilState(questionsAtom);

  useEffect(() => {
    const fetchInitialData = async () => {
      const fetchHeroImages = async () => {
        const query = '*[_type == "heroImage"] {order, image}';
        const params = {};

        return sanityClient
          .fetch(query, params)
          .then((results: any[]) => {
            setHeroImages(
              results
                .map(
                  ({
                    order,
                    image,
                  }: {
                    order: number;
                    image: SanityImageSource;
                  }) => {
                    return { order, src: urlForSanityImage(image).url() };
                  }
                )
                .sort((a, b) => a.order - b.order)
                .map(({ src }) => src)
            );
          })
          .catch(console.error);
      };

      const fetchArtistsAndDrops = async () => {
        const query = '*[_type == "artist"]';
        const params = {};

        sanityClient
          .fetch(query, params)
          .then((fetchedArtists: any[]) => {
            setArtists(
              fetchedArtists.map(
                ({ _id, name }: { _id: string; name: string }) => {
                  return {
                    _id,
                    name,
                  };
                }
              )
            );

            const query = '*[_type == "release"]';
            const params = {};

            sanityClient
              .fetch(query, params)
              .then((results: any[]) => {
                setDrops(
                  results
                    .map(
                      ({
                        _id,
                        _createdAt,
                        name,
                        artist: { _ref: artistRef },
                        coverArt,
                        openSeaURL,
                        releaseDate,
                      }: {
                        _id: string;
                        name: string;
                        artist: { _ref: string };
                        coverArt: SanityImageSource;
                        openSeaURL: string;
                        releaseDate: string;
                        _createdAt: string;
                      }) => {
                        return {
                          _id,
                          name,
                          artistName:
                            fetchedArtists.find(({ _id }) => _id === artistRef)
                              ?.name || "Unknown Artist",
                          createdAt: new Date(_createdAt),
                          openSeaURL,
                          coverArt: urlForSanityImage(coverArt)
                            .width(300)
                            .height(300)
                            .url(),
                          releaseDate: releaseDate
                            ? new Date(releaseDate)
                            : null,
                        };
                      }
                    )
                    .sort((a, b) =>
                      a.releaseDate && b.releaseDate
                        ? +a.releaseDate - +b.releaseDate
                        : +a.createdAt - +b.createdAt
                    )
                );
              })
              .catch(console.error);
          })
          .catch(console.error);
      };

      const fetchQuestions = async () => {
        const query = '*[_type == "question"]';
        const params = {};
        sanityClient.fetch(query, params).then((results: any[]) => {
          setQuestions(
            results
              .map(
                ({
                  order,
                  question,
                  answer,
                }: {
                  order: number;
                  question: string;
                  answer: any[];
                }) => {
                  return {
                    order,
                    question,
                    answer,
                  };
                }
              )
              .sort((a, b) => a.order - b.order)
          );
        });
      };

      const fetchHowItWorks = async () => {
        const query = '*[_type == "howItWorks"]';
        const params = {};

        sanityClient
          .fetch(query, params)
          .then((results: any[]) => {
            setHowItWorks(
              results
                .map(
                  ({
                    order,
                    title,
                    description,
                  }: {
                    order: number;
                    title: string;
                    description: string;
                  }) => {
                    return { order, title, description };
                  }
                )
                .sort((a, b) => a.order - b.order)
            );
          })
          .catch(console.error);
      };

      await fetchHeroImages();
      await fetchArtistsAndDrops();
      await fetchHowItWorks();
      await fetchQuestions();
      setIsFetchingInitialData(false);
    };

    fetchInitialData();
  }, []);

  return isFetchingInitialData ? (
    <FullScreenLoader />
  ) : (
    <div id="wrapper">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top" />
        <Hero />
        <Drops />
        <HowItWorks />
        {/* <Collections /> */}
        {/* <Popular /> */}
        {/* <Category /> */}
        <Questions />
      </div>
      <BackToTop />
      <Footer />
    </div>
  );
};

export default App;
