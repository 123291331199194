import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { doc, setDoc, getFirestore } from "firebase/firestore";

import "firebase/auth";

// Firebase Config
export const firebaseConfig = {
  apiKey: "AIzaSyAxY7frmY3_YPSNn5bc-podNvZiPIdjFiE",
  authDomain: "session-42-nft.firebaseapp.com",
  projectId: "session-42-nft",
  storageBucket: "session-42-nft.appspot.com",
  messagingSenderId: "397620512881",
  appId: "1:397620512881:web:f3fad5c484ba53820c73d0",
  measurementId: "G-EWWMH7LMYS",
  databaseURL: "",
};
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

export const addContact = async function (email: string) {
  await setDoc(doc(db, "contacts", email), {
    email,
    timestamp: new Date().toLocaleString("he-IL"),
  });
};

export const subscribeToDrop = async function (
  dropId: string,
  name: string,
  email: string
) {
  await setDoc(doc(db, "dropSubscriptions", dropId), {
    dropId,
    name,
  });
  await setDoc(doc(db, `dropSubscriptions/${dropId}/emails`, email), {
    email,
    timestamp: new Date().toLocaleString("he-IL"),
  });
};

export default app;
