/* eslint-disable no-useless-escape */
import { FunctionComponent, useState } from "react";
import { addContact } from "../services/firebase";
import { validateEmail } from "../utils/email";

const InnerForm: FunctionComponent<{
  onContactEmailSubmit: ({
    email,
    setSent,
  }: {
    email: string;
    setSent: React.Dispatch<React.SetStateAction<boolean>>;
  }) => Promise<void>;
}> = ({ onContactEmailSubmit }) => {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);

  return (
    <div className="row">
      <div className="col-12 col-xl-4 session-follow-header text-left text-xl-right">
        Sign up for updates
      </div>
      {sent ? (
        <div className="col-12 col-xl-8 text-left">
          Your message has been sent.
          <br />
          We will contact you very soon. Thank you!
        </div>
      ) : (
        <div className="col-12 col-xl-8 p-3 pt-xl-0 pb-xl-0">
          <form className="d-flex">
            <div className="flex-grow-1 pl-0">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control email-input"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  onChange={(evt) => setEmail(evt.target.value)}
                />
              </div>
            </div>
            <div className="pl-4">
              <button
                type="submit"
                className="btn btn-link text-decoration-none text-white border-bottom-white contact-submit-btn nowrap"
                onClick={(evt) => {
                  evt.preventDefault();
                  onContactEmailSubmit({
                    email,
                    setSent,
                  });
                }}
              >
                Sign up
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const ContactForm: FunctionComponent<{}> = () => {
  const onContactEmailSubmit = async ({
    email,
    setSent,
  }: {
    email: string;
    setSent: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    if (email) {
      try {
        if (!validateEmail(email)) {
          window.alert("Please enter a correct email");
          return setSent(false);
        }
        await addContact(email);
        setSent(true);
      } catch (err) {
        console.error("Failed to save contact", err);
        setSent(false);
      }
    }
  };
  return <InnerForm onContactEmailSubmit={onContactEmailSubmit} />;
};

export default ContactForm;
