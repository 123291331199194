import React, { FunctionComponent } from "react";
import ContactForm from "./ContacForm";

const Footer: FunctionComponent<{}> = () => {
  return (
    <footer className="pt-80px pb-80px text-center" dir="ltr">
      <div className="mx-4 mx-lg-2">
        <div className="m-auto max-w-500px max-w-md-600px max-w-lg-800px max-w-xl-100">
          <div className="row m-0">
            <div className="col-12 col-xl-6">
              <div className="row">
                <div className="col-12 col-xl-6 session-footer-link text-left text-md-center text-xl-left text-xl-left mb-4">
                  <a
                    href="https://session-42.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GO TO SESSION42{" "}
                    <span className="nowrap">
                      WEBSITE
                      <svg
                        className="arrow-icon"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M15.2458 13.2344L14.5997 0.862786L2.22804 1.50887L2.33203 3.50019L11.4168 2.96204L0.795509 14.7538L2.2444 16.0589L12.8456 4.2894L13.2322 13.3184L15.2458 13.2344Z" />
                      </svg>
                    </span>
                  </a>
                </div>
                <div className="col-12 col-xl-6 mt-4 mt-xl-0 mb-4 mb-xl-0">
                  <div className="row">
                    <div className="col-3 session-follow-header text-left">
                      Follow
                    </div>
                    <div className="col-9 pl-2 pl-4">
                      <div className="row">
                        <div className="col justify-content-between">
                          <div className="row text-left">
                            <a
                              href="https://open.spotify.com/user/317tzv3cbuevhe5vynp4aunuguhq?si=71eaa3df6592456d"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Spotify
                            </a>
                            <a
                              href="https://www.youtube.com/channel/UCNum6APXhUe_m1HxjCbIf0g"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Youtube
                            </a>
                            <a
                              href="https://www.linkedin.com/company/session-42/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              LinkedIn
                            </a>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row text-left">
                            <a
                              href="https://www.facebook.com/Session-42-106306088618382"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Facebook
                            </a>
                            <a
                              href="https://www.instagram.com/session42.music/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Instagram
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 mt-4 mt-xl-0 mb-4 mb-xl-0">
              <ContactForm />
              <div className="row mt-3">
                <div className="col-4"></div>
                <div className="col-12 col-xl-6 px-3 pt-4 pt-xl-0">
                  <div className="text-center text-xl-left">
                    © all rights reserved session42
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
