import React, { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";

import { questionsAtom } from "../state/atoms";

import Question from "./Question";

const Questions: FunctionComponent<{}> = () => {
  const questions = useRecoilValue(questionsAtom);

  return (
    <section id="section-questions">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h1 className="m-0 hero-h1">Questions Answered</h1>
              <p className="subtitle">תשובות לכל השאלות שלכם</p>
              <div className="spacer-single" />
            </div>
          </div>
          {questions.map(({ order, question, answer }, i) => (
            <Question
              key={`question-${i}`}
              order={order}
              question={question}
              answer={answer}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Questions;
