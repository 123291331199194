import React, { FunctionComponent } from "react";
import LottieLoader from "./LottieLoader/LottieLoader";

const FullScreenLoader: FunctionComponent<{}> = () => {
  return (
    <div className="h-100vh d-flex align-items-center flex-column justify-content-center">
      <LottieLoader></LottieLoader>
    </div>
  );
};

export default FullScreenLoader;
