import React, { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";
import { dropsAtom } from "../state/atoms";
import DropCard from "../components/DropCard";

const Drops: FunctionComponent<{}> = () => {
  const drops = useRecoilValue(dropsAtom);

  return (
    <section id="section-drops">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <div>
                <img
                  src="/images/asterisk.png"
                  alt=""
                  style={{ width: "40px" }}
                />
              </div>
              <div className="spacer-single" />
              <h1 className="hero-h1">Fresh Drops</h1>
              {/* <p className="subtitle">Uncomment me if needed</p> */}
            </div>
          </div>
          <div className="row m-0 px-4">
            {drops.map((release, i) => (
              <DropCard key={`drop-card-${i}`} release={release} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Drops;
