import { FunctionComponent, useState } from "react";

const Header: FunctionComponent<{}> = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <>
      {/* Mobile */}
      <header className="d-block d-lg-none position-fixed p-0 overflow-visible">
        <div className="p-4 pb-5 bg-black">
          <div className="float-left">
            <div id="mobile-logo">
              <a className="w-100 h-100" href="/">
                <img
                  alt=""
                  style={{ maxHeight: "27px" }}
                  src="/images/logo-pink.svg"
                />
              </a>
            </div>
          </div>
          <div className="float-right">
            <div
              id="hamburger"
              className="cursor-pointer"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              style={{ width: "33px", height: "33px" }}
            >
              {isMobileMenuOpen ? (
                <img
                  alt=""
                  style={{ width: "50px" }}
                  src="/images/times-pink.svg"
                />
              ) : (
                <img alt="" src="/images/equals-pink.svg" />
              )}
            </div>
          </div>
        </div>
        {isMobileMenuOpen && (
          <div
            className="w-100 row bg-none p-0 m-0 text-right text-20px font-weight-500"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <a
              className="col-6 bg-black-transparent-80 p-4 mobile-header-menu-link"
              href="#section-drops"
            >
              מרקטפלייס
            </a>
            <div className="col-6" />
            <a
              className="col-6 bg-black-transparent-80 p-4 mobile-header-menu-link"
              href="#section-how-it-works"
            >
              איך זה עובד
            </a>
            <div className="col-6" />
            <a
              className="col-6 bg-black-transparent-80 p-4 mobile-header-menu-link"
              href="#section-questions"
            >
              שאלות ותשובות
            </a>
            <div className="col-6" />
            <a
              className="col-6 bg-black-transparent-80 p-4 mobile-header-menu-link"
              href="https://discord.com/channels/952912413957685268/952912413957685271"
              target="_blank"
              rel="noreferrer"
            >
              עזרה
              {/* <img
                    src="/images/arrow-top-right-white.svg"
                    style={{ height: "16px" }}
                    alt="עזרה"
                  /> */}
            </a>
            <div className="col-6" />
            <div className="col-6 bg-black-transparent-80 p-4">
              <div className="spacer-60" />
              <div className="spacer-60" />
              <div className="spacer-60" />
              <div className="spacer-60" />
              <div className="spacer-60" />
              <div className="spacer-60" />
            </div>
          </div>
        )}
      </header>
      {/* Desktop */}
      <header
        className="transparent header-light scroll-light d-none d-lg-block"
        style={{ height: "100px", fontSize: "1.2rem" }}
      >
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-4">
              <a
                href="#section-drops"
                className="desktop-header-menu-link d-inline ml-4"
              >
                מרקטפלייס
              </a>
              <a
                href="#section-how-it-works"
                className="desktop-header-menu-link d-inline mr-4"
              >
                איך זה עובד
              </a>
            </div>
            <div className="col-4 text-center">
              <div id="logo">
                <a className="w-100 h-100" href="/">
                  <img alt="" src="/images/logo-pink.svg" />
                </a>
              </div>
            </div>
            <div className="col-4 text-left">
              <a
                href="#section-questions"
                className="desktop-header-menu-link d-inline ml-4"
              >
                שאלות ותשובות
              </a>
              <a
                href="https://discord.com/channels/952912413957685268/952912413957685271"
                target="_blank"
                rel="noreferrer"
                className="desktop-header-menu-link d-inline mr-4"
              >
                עזרה
                {/* <svg
                  className="arrow-icon"
                  width="12"
                  height="12"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2458 13.2344L14.5997 0.862786L2.22804 1.50887L2.33203 3.50019L11.4168 2.96204L0.795509 14.7538L2.2444 16.0589L12.8456 4.2894L13.2322 13.3184L15.2458 13.2344Z"
                    fill="#000000"
                  />
                </svg> */}
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
