/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useState } from "react";
import Marquee from "react-easy-marquee";
import { subscribeToDrop } from "../services/firebase";

import { DateAndTime, IDrop } from "../types";
import { isDateInFuture, timeLeftToDate } from "../utils/date";
import { validateEmail } from "../utils/email";

const DropCard: FunctionComponent<{
  release: IDrop;
}> = ({
  release: { _id, name, artistName, coverArt, openSeaURL, releaseDate },
}) => {
  const isFuture = isDateInFuture(releaseDate);
  const isActive = !!(releaseDate && !isFuture);
  const isSoon = !isActive && !isFuture;

  const [timeLeft, setTimeLeft] = useState<DateAndTime | null>(null);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (isFuture) {
      const interval = setInterval(() => {
        setTimeLeft(timeLeftToDate(releaseDate));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isFuture, releaseDate]);

  const {
    days: daysLeft,
    hours: hoursLeft,
    minutes: minutesLeft,
    seconds: secondsLeft,
  } = timeLeft || {};

  return (
    <div className="col-lg-3 col-md-6 col-sm-12 p-0 m-0">
      <div className="owl-item active">
        <div className="d-item" style={{ display: "block" }}>
          <div className="nft__item">
            <div className="nft__item_wrap">
              <div
                className={`nft__item_content lazy nft__item_preview w-100 aspect-ratio-1-1 ${
                  isSoon ? "blurred darken" : ""
                } ${isFuture ? "blurred darken" : ""}`}
                style={{
                  backgroundImage: `url('${coverArt}')`,
                }}
              />
              {/* <img src={coverArt} className="lazy nft__item_preview" alt="" /> */}
              <div className="position-absolute text-white w-100">
                {isFuture && (
                  <>
                    <div className="row mt-2 w-100 w-lg-75 m-auto">
                      <div>
                        <div className="d-inline-block w-25 text-white font-weight-600 mb-0 timer-unit text-shadow">
                          {secondsLeft?.toFixed().padStart(2, "0")}
                        </div>
                        <div className="d-inline-block w-25 text-white font-weight-600 mb-0 timer-unit text-shadow">
                          {minutesLeft?.toFixed().padStart(2, "0")}
                        </div>
                        <div className="d-inline-block w-25 text-white font-weight-600 mb-0 timer-unit text-shadow">
                          {hoursLeft?.toFixed().padStart(2, "0")}
                        </div>
                        <div className="d-inline-block w-25 text-white font-weight-600 mb-0 timer-unit text-shadow">
                          {daysLeft?.toFixed().padStart(2, "0")}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-1 w-100 w-lg-75 m-auto">
                      <div>
                        <div className="d-inline-block w-25 text-white font-weight-600 timer-descr text-shadow mb-0">
                          S
                        </div>
                        <div className="d-inline-block w-25 text-white font-weight-600 timer-descr text-shadow mb-0">
                          M
                        </div>
                        <div className="d-inline-block w-25 text-white font-weight-600 timer-descr text-shadow mb-0">
                          H
                        </div>
                        <div className="d-inline-block w-25 text-white font-weight-600 timer-descr text-shadow mb-0">
                          D
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 brand-background p-0 release-marquee-container">
                      <h4 className="m-0 text-white p-0">
                        <Marquee
                          duration={5000}
                          height="2.2rem"
                          className="p-0"
                        >
                          &nbsp;Release In&nbsp;Release In&nbsp;Release
                          In&nbsp;Release In&nbsp;Release In
                        </Marquee>
                      </h4>
                    </div>
                  </>
                )}
                {isSoon && (
                  <div className="text-white font-weight-600 mb-0 timer-unit text-shadow text-shadow">
                    SOON
                  </div>
                )}
              </div>
            </div>
            <div className="nft__item_info text-center">
              <h4>{name}</h4>
              <div className="nft__item_click">
                <span />
              </div>
              <div className="nft__item_price">{artistName}</div>
              <div className="mt-4" style={{ height: "3.2rem" }}>
                {isSubscribed ? (
                  <>
                    <h2
                      dir="ltr"
                      className="m-0"
                      style={{
                        top: "-0.75rem",
                      }}
                    >
                      Got it!
                    </h2>
                    <div
                      style={{
                        marginTop: "-1.2rem",
                        fontSize: "0.8rem",
                        fontWeight: 600,
                      }}
                    >
                      נרשמת בהצלחה!
                    </div>
                  </>
                ) : isSubscribing ? (
                  <>
                    <form
                      className="form w-100 d-flex"
                      style={{ height: "2.4rem" }}
                      onSubmit={async (evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        try {
                          if (email) {
                            if (!validateEmail(email)) {
                              return setIsErrored(true);
                            }
                            subscribeToDrop(_id || "unknown", name, email);
                            setIsSubscribed(true);
                            setIsSubscribing(false);
                          }
                        } catch (e) {
                          console.error("Error subscribing to drop", e);
                          setIsSubscribing(false);
                        }
                      }}
                    >
                      <button
                        className="btn bg-black align-middle"
                        type="submit"
                      >
                        <svg
                          width="12"
                          height="15"
                          viewBox="0 0 13 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.68111 0L0 2.6197L4.48354 6.97854L5.71144 8.13429H5.67764L7.61525 10.0165L5.66638 11.8987H5.67764L5.20451 12.35L0 17.3913L2.68111 20L13 10.0165L2.68111 0Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                      <input
                        dir="ltr"
                        type="text"
                        className={`form-item border-2 flex-grow-1 text-left px-2 py-1 align-middle ${
                          isErrored ? "border-red" : ""
                        }`}
                        placeholder="your@email.here"
                        value={email}
                        style={{
                          maxWidth: "calc(100% - 2.5rem)",
                        }}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setIsErrored(false);
                        }}
                      />
                    </form>
                    {isErrored && (
                      <div
                        style={{
                          fontSize: "0.8rem",
                          color: "#ff0000",
                        }}
                      >
                        כתובת לא תקינה
                      </div>
                    )}
                  </>
                ) : (
                  <a
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();
                      if (isActive) {
                        window.open(openSeaURL, "_blank");
                      } else {
                        setIsSubscribing(true);
                      }
                    }}
                    className={`btn btn-${
                      isActive ? "" : "outline-"
                    }primary border-2 w-100`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {isActive ? "לרכישה" : "הרשמה לקבלת עדכון"}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropCard;
