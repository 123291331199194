import React, { FunctionComponent } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import { heroImagePageAtom, heroImagesAtom } from "../state/atoms";

const HeroSwiper: FunctionComponent<{}> = () => {
  const heroImages = useRecoilValue(heroImagesAtom);
  const setHeroImagePage = useSetRecoilState(heroImagePageAtom);
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={(evt) => setHeroImagePage(evt.realIndex || 0)}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      centeredSlides={true}
      modules={[Autoplay]}
      allowSlideNext={heroImages.length > 1}
      allowSlidePrev={heroImages.length > 1}
      allowTouchMove={heroImages.length > 1}
    >
      {heroImages.map((image, index) => {
        return (
          <SwiperSlide key={`slider-${index}`}>
            <img src={image} alt="" />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default HeroSwiper;
