import { atom } from "recoil";
import { IArtist, IDrop, IHowItWorks, IQuestion } from "../types";

export const isFetchingInitialDataAtom = atom<boolean>({
  key: "isFetchingInitialDataAtom",
  default: true,
});

export const heroImagesAtom = atom<string[]>({
  key: "heroImagesAtom",
  default: [],
});

export const heroImagePageAtom = atom<number>({
  key: "heroImagePageAtom",
  default: 0,
});

export const artistsAtom = atom<IArtist[]>({
  key: "artistsAtom",
  default: [],
});

export const dropsAtom = atom<IDrop[]>({
  key: "dropsAtom",
  default: [],
});

export const howItWorksAtom = atom<IHowItWorks[]>({
  key: "howItWorksAtom",
  default: [],
});

export const questionsAtom = atom<IQuestion[]>({
  key: "questionsAtom",
  default: [],
});
