import Lottie from "lottie-react";
import lottieLoaderAnimation from "./LottieLoader.json";

const style = {
  height: 300,
};

const LottieLoader = () => {
  return <Lottie style={style} animationData={lottieLoaderAnimation} />;
};

export default LottieLoader;
