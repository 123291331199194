import { DateAndTime } from "../types";

export const isDateInFuture = (date: Date | null): boolean => {
  return !!(date && date > new Date());
};

export const timeLeftToDate = (date: Date | null): DateAndTime => {
  if (!date) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const now = new Date();
  const diff = date.getTime() - now.getTime();

  return {
    days: Math.floor(diff / (1000 * 60 * 60 * 24)),
    hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((diff / 1000 / 60) % 60),
    seconds: Math.floor((diff / 1000) % 60),
  };
};
