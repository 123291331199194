import React, { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";
import { heroImagesAtom, heroImagePageAtom } from "../state/atoms";
import HeroSwiper from "./HeroSwiper";

const Hero: FunctionComponent<{}> = () => {
  const heroImages = useRecoilValue(heroImagesAtom);
  const heroImagePage = useRecoilValue(heroImagePageAtom);

  const SwiperDots = () => (
    <div className="m-auto mb-2">
      {heroImages.map((_, index) => (
        <span
          key={`image-page-${index}`}
          className="m-4 p-0"
          style={{
            width: "10px",
            height: "10px",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="5.5"
              cy="5.5"
              r="5.5"
              fill={index === heroImagePage ? "#ff99ff" : "#e9e9e9"}
            />
          </svg>
        </span>
      ))}
    </div>
  );

  return (
    <section
      id="section-hero"
      aria-label="section"
      className="pt-80px pt-lg-150px no-bottom bg-black text-white"
    >
      {/* Desktop */}
      <div className="container-xl">
        <div className="row align-items-center">
          <div className="col-12 d-none d-lg-block text-center">
            <h1
              className="wow fadeInUp animated m-0 hero-h1 brand-text"
              data-wow-delay=".75s"
              style={{
                visibility: "visible",
                animationDelay: "0.75s",
                animationName: "fadeInUp",
              }}
            >
              Be The Owner Of
            </h1>
            <h1
              className="wow fadeInUp animated m-0 hero-h1 brand-text"
              data-wow-delay=".75s"
              style={{
                visibility: "visible",
                animationDelay: "0.75s",
                animationName: "fadeInUp",
              }}
            >
              Your Favorite Music
            </h1>
            <div className="spacer-single" />
          </div>
          <div className="row m-0 p-0 w-100 position-relative align-items-center">
            <HeroSwiper />
            <div className="d-block d-lg-none position-absolute bottom-0 col-12 text-center">
              <SwiperDots />
            </div>
          </div>
          <div className="d-none d-lg-block col-12 my-3 text-center">
            <SwiperDots />
          </div>
          <div className="col-12 d-block d-lg-none text-center">
            <div className="spacer-single" />
            <h1
              className="wow fadeInUp animated m-0 hero-h1 brand-text"
              data-wow-delay=".75s"
              style={{
                visibility: "visible",
                animationDelay: "0.75s",
                animationName: "fadeInUp",
              }}
            >
              Be The Owner Of
            </h1>
            <h1
              className="wow fadeInUp animated m-0 hero-h1 brand-text"
              data-wow-delay=".75s"
              style={{
                visibility: "visible",
                animationDelay: "0.75s",
                animationName: "fadeInUp",
              }}
            >
              Your Favorite Music
            </h1>
            <div className="spacer-single" />
          </div>
        </div>
      </div>
      {/* Both mobile & desktop */}
      <div className="container mt-0">
        <div className="row align-items-center">
          <div className="col-12 text-center">
            <p
              className="wow fadeInUp lead animated brand-text"
              data-wow-delay="1s"
              style={{
                visibility: "visible",
                animationDelay: "1s",
                animationName: "fadeInUp",
              }}
            >
              מהיום אתם הבעלים
              <br />
              של המוזיקה האהובה עליכם
            </p>
            <div className="spacer-single" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
