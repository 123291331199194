import React, { FunctionComponent, useState } from "react";
import { PortableText } from "@portabletext/react";

const Question: FunctionComponent<{
  order: number;
  question: string;
  answer: any[];
}> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="col-12 cursor-pointer question-container"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="row">
          <div className="col-10 text-right">
            <h3 className="m-0">{question}</h3>
          </div>
          <div className="col-2">
            <div
              className="float-left"
              style={{ width: "30px", height: "30px" }}
            >
              <img
                className="lazy w-100"
                src={isOpen ? "/images/minus.png" : "/images/plus.png"}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="col-12 pt-4" onClick={() => setIsOpen(!isOpen)}>
          <p
            className="wow fadeInDown lead animated"
            data-wow-delay="0s"
            style={{
              visibility: isOpen ? "visible" : "hidden",
              animationDelay: "0s",
              animationName: "fadeInDown",
            }}
          >
            <PortableText value={answer} />
          </p>
        </div>
      )}
    </>
  );
};

export default Question;
