import React, { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";

import { howItWorksAtom } from "../state/atoms";

const HowItWorks: FunctionComponent<{}> = () => {
  const howItWorksSteps = useRecoilValue(howItWorksAtom);

  return (
    <section
      id="section-how-it-works"
      className="no-top no-bottom how-it-works-container position-relative p-0"
    >
      <video className="background-video" autoPlay loop muted>
        <source src="/images/gradient.mp4" type="video/mp4" />
      </video>
      <div className="container pt90 pb90">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <h1 className="m-0 hero-h1">How it works</h1>
              <p className="subtitle">איך זה עובד?</p>
              <div className="spacer-single" />
            </div>
          </div>
        </div>
        <div className="row">
          {howItWorksSteps.map(({ order, title, description }, i) => (
            <div
              key={`how-to-step-${i}`}
              className="col-lg-4 col-md-6 mb-sm-30"
            >
              <div className="card how-it-works-card p-5">
                <div className="text text-center">
                  <h2
                    className="wow fadeInUp animated hero-h2 p-3"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInUp",
                    }}
                  >
                    {order.toString().padStart(2, "0")}
                  </h2>
                </div>
                <div className="text text-center">
                  <h4
                    className="wow fadeInUp animated w-100 w-md-75 m-auto mb-4"
                    style={{
                      height: "50px",
                      visibility: "visible",
                      animationName: "fadeInUp",
                      fontWeight: 500,
                    }}
                  >
                    {title}
                  </h4>
                  <p
                    className="wow fadeInUp animated text-right mt-4 how-it-works-description"
                    data-wow-delay=".25s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.25s",
                      animationName: "fadeInUp",
                    }}
                  >
                    {description}
                  </p>
                </div>
                <i className="wm icon_wallet" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="logo-loop-container">
        <div className="logo-loop-stripe" />
      </div>
    </section>
  );
};

export default HowItWorks;
